import React from 'react';
import Layout from '../components/layout';

import Title from '../components/Title';

import StyledHero from "../components/StyledHero";
import { Link, graphql } from 'gatsby';

import * as styles from '../css/privacy.module.css';
import Seo from '../components/SEO';

import { getSrc } from "gatsby-plugin-image";

const privacy = (props) => {
  const { data } = props;
  console.log("privacy page props:", props);
  
  const seoImage = {
          src: getSrc(data.seoImage),
          height: data.seoImage.childImageSharp.gatsbyImageData.height,
          width: data.seoImage.childImageSharp.gatsbyImageData.width,
          alt: 'Computer technical words written on a big screen',
  };

  return (
    <Layout>
      <Seo
        siteTitle={'Privacy'}
        seoTitle='Privacy Policy for Malaud LLC'
        path={props.location.pathname}
        metaImage={seoImage}
        description='Privacy Policy for Malaud.com'
        keywords={[]}
        datePublished="04-03-2020"
        dateModified="11-01-2022"
      />                    
        
      <StyledHero overlay={true} img={data.defaultBcg.childImageSharp.gatsbyImageData} />

    <div className={styles.privacy}>
      <div className={styles.title}>
            <Title title="Privacy" subtitle="Policy"/>
      </div><br />
      
      <div>
        <p className={styles.info}>This section explains the privacy and cookie policy for this website. If you need more information, 
            you may <Link style={{textDecoration: 'none', color: '#3fd0d4'}} to="/contact">contact us</Link>.
        </p><br />

        <ol>
          <li><h4 className={styles.itemName}>Data we collect and how we use it</h4>

            {/* <h5 className={styles.subitemName}>Personal Information</h5> */}
            <p>
              <span style={{fontWeight: 'bold'}}>Personal Information: </span>
              We do not collect your personal information on this website, except as noted below.
            </p>

            <p>
              <span style={{fontWeight: 'bold'}}>Location Information: </span>
              We may use anonymized IP address of the visitors for analytical purposes.
            </p>

            <p>
              <span style={{fontWeight: 'bold'}}>Anonymous Information: </span>
              We may use visitors' anonymized IP address to determine the country, browser's user agent string to identify the browser used, and visitor data about page views on our website, etc. This data is used for data analytics, usage metrics, and for spam detection.
            </p>
            </li>

            <li><h4 className={styles.itemName}>Cookie Policy</h4>
                <p>
                    We may store cookies that only collects anonymous information about the usage of this website, 
                    for analytics and historical data. The cookies are not used to track you across websites and apps, and are not used for advertisements. These cookies may last up to two years.
                </p>

                <h5 className={styles.subitemName}>Embedded content from other websites</h5>
                <p>This website doesn't include any embedded content at this time, but may do so in the future. 
                    This privacy policy will be updated to include specifics about third party content, when that 
                    happens.</p>
                <br />
            </li>

            <li><h4 className={styles.itemName}>Analytics and Storage</h4>

                <h5 className={styles.subitemName}>Data Sharing</h5>
                <p>Except as noted above, we do not collect or share your personal data on this website.</p>

                <h5 className={styles.subitemName}>Data Retention</h5>
                <p>When you contact support via
                    the <Link style={{textDecoration: 'none', color: '#3fd0d4'}} to="/contact">contact</Link> page, 
                    you provide us with your name and email address. This information will be stored on our servers and for the sole purpose of answering your support request and for legal & administrative purposes.</p>
                <br />
            </li>

            <li><h4 className={styles.itemName}>Your Rights</h4>
                <p>You may ask for a copy of your data that we have in our records. Your data can only be requested to be sent to the email address we have in our records for you. You may also request that we erase any personal data we hold about you. This does not include any data that is required to be maintained for legal, administrative, or security purposes.</p>
            
            </li>
            </ol>

          <div className={styles.footWrap}>
            <Link className={styles.footer} to="/">Back to Home Page</Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default privacy

export const privacyQuery = graphql`{
  defaultBcg: file(relativePath: {eq: "privacyBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
  seoImage: file(relativePath: {eq: "privacyBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 600)
    }
  }
}
`